import React, { useState } from "react";
import H2 from "~/components/Typography/H2";
import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import UnorderedList from "~/components/List/Unordered";
import { StudentCard, TeacherCard } from "~/components/content-card";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
    <LessonLayout
      current_step={10}
      lesson={"Lesson 4"}
      color={"hs"}
      total_count={15}
      module_title="What Could Happen?"
    >
      <ContentWrapper>
        <H2 underline>Step 10</H2>
        <TeacherViewWrapper>
          <StudentCard
            fullScreen={studentFullScreen}
            hidden={teacherFullScreen}
            highSchool={true}
            setFullScreen={setStudentFullScreen}
          >
            <p>What can people do if they have concerns about an OTC medication or a dietary supplement? They can contact the FDA or another agency, the Federal Trade Commission (FTC).</p>
            <p>The FTC can file complaints or charges against marketing companies that make invalid claims in ads.</p>
            <p>The FTC has rules for ads for these products. They include the following:</p>

            <UnorderedList>
              <li>Ads must be truthful.</li>
              <li>Ads must have evidence to back up their claims.</li>
            </UnorderedList>

            <p>The amount of evidence can depend on the claim made. At a minimum, the company must have the evidence it claims to have. For example, a company cannot say that “2 out of 3 doctors recommend ABC pain reliever” if they have not done a reliable survey to get this result. In other cases, companies should have scientific tests or studies that have been reviewed by appropriate experts.</p>
          </StudentCard>
          <TeacherCard
            fullScreen={teacherFullScreen}
            hidden={studentFullScreen}
            highSchool={true}
            setFullScreen={setTeacherFullScreen}
          >
            <p>A goal for these lessons is for students to be able to find information about health issues. In addition to the FDA, the FTC can be another valuable source of information for consumers who are concerned about the quality of a product or the claims being made about a health-related product. The FTC acts when a manufacturer makes unsubstantiated claims in their advertisements or marketing materials.</p>
          </TeacherCard>
        </TeacherViewWrapper>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

